<template>
  <div class="page-user-recipesview">
    <div class="my-page">
      <div class="my-card">
        <div class="chose-box">
          <div class="left">{{ time ? time : '今天' }}</div>
        </div>
        <!-- 日历 -->
        <div class="date-box">
          <van-calendar
            :show-title="false"
            :min-date="minDate"
            :poppable="false"
            :show-confirm="false"
            :style="{ height: '380px' }"
            color="#ffa200"
            @select="selectDate"
          />
        </div>
        <!-- 作息进度 -->
        <div class="step-box">
          <template v-if="dataObj && dataObj.RecipesType === 1">
            <template v-if="dataList.length > 0">
              <van-steps
                class="step"
                direction="vertical"
                active-color="#ffa200"
                inactive-color="#ffa200"
                active-icon="underway"
                inactive-icon="underway"
                :active="0"
              >
                <van-step v-for="(item, i) in dataList" :key="i">
                  <h3 class="step-times">
                    <span>{{ item.MealsTypeName }}</span>
                    {{ item.RecipesContent }}
                  </h3>
                  <p class="step-img">
                    <van-image
                      v-for="(img, k) in item.AccessoryPostList"
                      :key="k"
                      width="100"
                      height="74"
                      lazy-load
                      :src="img.url"
                      @click="
                        () => {
                          images = []
                          images.push(img.url)
                          imgShow = true
                        }
                      "
                      style="margin-right: 10px;margin-bottom: 10px;border-radius: 6px;overflow: hidden;display: inline-block;"
                    />
                  </p>
                </van-step>
              </van-steps>
            </template>
            <van-empty description="暂无数据" v-else />
          </template>
          <template v-if="dataObj && dataObj.RecipesType === 2">
            <p class="step-img">
              <van-image
                v-for="(img, k) in imgList"
                :key="k"
                width="100"
                height="74"
                lazy-load
                :src="img.url"
                @click="
                  () => {
                    images = []
                    images.push(img.url)
                    imgShow = true
                  }
                "
                style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: inline-block;"
              />
            </p>
          </template>
          <van-empty description="暂无数据" v-else />
        </div>
      </div>
      <van-button type="primary" block color="#ffe500" @click="edit()"
        >编辑</van-button
      >
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import {
  Button,
  Icon,
  Calendar,
  Step,
  Steps,
  Empty,
  Image,
  ImagePreview
} from 'vant'

import pic1 from '@/assets/img/recipes-pic1.png'
import pic2 from '@/assets/img/recipes-pic2.png'
import pic3 from '@/assets/img/recipes-pic3.png'

export default {
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Calendar.name]: Calendar,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Empty.name]: Empty,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data () {
    return {
      minDate: new Date(2019, 0, 1),
      time: '',
      restList: [],
      imgShow: false,
      images: [],
      pic1: pic1,
      pic2: pic2,
      pic3: pic3,
      dataObj: null,
      dataList: [],
      imgList: []
    }
  },
  mounted () {
    this.getRestList()
  },
  methods: {
    getRestList () {
      const params = {
        beginDate: this.time
      }
      this.dataObj = null
      this.dataList = []
      this.imgList = []
      console.log(params)
      this.$axios.get('/api/Recipes/Get', params).then(res => {
        if (res.data || res.data.length > 0) {
          this.dataObj = res.data
          this.dataList = res.data.imageTextList || []
          this.imgList = res.data.accessoryList || []
        }
      })
    },
    edit () {
      this.$toast('功能正在开发中...')
    },
    selectDate (date) {
      this.time = this.$moment(date).format('YYYY-MM-DD')
      this.getRestList()
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
